<template>
  <v-container fluid>
    <v-col
      v-if="loading"
      cols="12">
      <div class="text-center">
        <v-progress-circular
          color="primary"
          indeterminate/>
      </div>
    </v-col>
    <v-data-table
      :headers="headers"
      :items="consultingAppointments"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :items-per-page-options="rowsPerPage"
      class="elevation-1"
      v-else
    >
      <template
        slot="item"
        slot-scope="props">
        <tr
          class="-clickable"
          @click="openConsultingAppointmentDetails(props.item.id)">
          <td>{{ props.item | fullId('B') }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.date | datetime }}</td>
          <td>
            <template v-if="props.item.product">
              {{ props.item.product.fullname }}
            </template>
            <template v-else>
              Kein Produkt ausgewählt
            </template>
          </td>
          <td>{{ props.item.status | consultingAppointmentStatus }}</td>
          <td>{{ props.item.created_at | datetime }}</td>
          <td>
            <router-link :to="'/appointments/consulting/' + props.item.id">
              <v-btn
                color="primary"
                dark
                small>
                <v-icon>search</v-icon>
              </v-btn>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import AppointmentsAPI from '@/api/appointments'

export default {
  data() {
    return {
      sortBy: 'created_at',
      sortDesc: true,
      headers: [
        {
          text: 'Id',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Termin',
          sortable: true,
          value: 'date',
        },
        {
          text: 'Produkt',
          sortable: true,
          value: 'product_model_id',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
        },
        {
          text: 'Erstellt am',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Aktionen',
          value: 'name',
        },
      ],
      rowsPerPage: [25, 50, { text: 'Alle', value: -1 }],
      tableOptions: {
        sortDesc: true,
        sortBy: 'created_at',
      },
      consultingAppointments: [],
      loading: false,
    }
  },
  created() {
    this.loadConsultingAppointments()
  },
  methods: {
    loadConsultingAppointments() {
      this.loading = true
      AppointmentsAPI.getConsultingAppointments().then((response) => {
        this.consultingAppointments = response
        this.loading = false
      })
    },
    openConsultingAppointmentDetails(id) {
      this.$router.push({
        name: 'consulting_appointment',
        params: {
          id,
        },
      })
    },
  },
}
</script>
